import React, { useState } from "react";
import "./App.css";

function App() {
  const [waitlistView, setWaitlistView] = useState(false);
  // Tally.loadEmbeds();

  return (
    <div>
      {waitlistView ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <iframe
            src="https://tally.so/embed/w7J6p2?&transparentBackground=1&dynamicHeight=1"
            className="w-full h-[529px]"
            title="Pumpkin Waitlist"
          ></iframe>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen gap-10 bg-[#fffbf6]">
          <div className="flex flex-row justify-start items-center">
            <img src="pumpkin.svg" alt="Pumpkin Logo" className="w-14 h-14" />
            <h1 className="ml-3 pb-2 text-7xl font-bold leading-7 text-[#344249]">
              Pumpkin
            </h1>
          </div>
          <div>
            <p className="-mt-4 -mb-4 text-xl text-[#313f49] font-normal">
              Your artificially intelligent web browser
            </p>
          </div>
          <div>
            <button
              className="text-lg bg-[#202d36] rounded-3xl text-[#fffbf6] font-normal px-8 py-2
            relative overflow-hidden group"
              onClick={() => setWaitlistView(true)}
            >
              <span className="relative z-10 group-hover:text-black">
                Join the waitlist
              </span>
              <div className="absolute inset-0 bg-[#ffb196] transform -translate-x-full transition-transform duration-300 ease-out group-hover:translate-x-0"></div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
